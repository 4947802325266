import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Packages
import { Col, Container, Row } from 'react-bootstrap';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

// Components
import LoadingSpinner from '../utils/LoadingSpinner';
import RouteInfo from './RouteInfo';
import IndicatorsTable from './IndicatorsTable';
import IndicatorsList from './IndicatorsList';
import IndicatorsMissingLoc from './IndicatorsMissingLoc';
import IndicatorsRetry from './IndicatorsRetry';
import IndicatorsIssues from './IndicatorsIssues';

// Actions
import { get_indicators_config, get_indicators_filters, get_indicators_progress, indicators_progress_loading_true } from '../../actions/indicatorsActions';
import { get_route_cycle } from '../../actions/organizationActions';
import { get_org_reports } from '../../actions/reportsActions';

// Utils
import isEmpty from '../../utils/isEmpty';

const Indicators = () => {
	const _ls = { ...localStorage };

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { cycle_id, route_id } = useParams();

	const { route_cycle } = useSelector(state => state.organization);

	const { indicators_filters, indicators_progress_loading, indicators_progress } = useSelector(state => state.indicators);

	const { org_reports } = useSelector(state => state.reports);

	const [parentIndicators, setParentIndicators] = useState([]);
	const [indicatorsToShow, setIndicatorsToShow] = useState([]);

	const [orgReports, setOrgReports] = useState([]);

	const addIndicators = () => {
		let parent = [...parentIndicators];
		let indicators = [...indicatorsToShow];

		indicators_filters.forEach((group) => {
			parent.push(group['group']?._id?.$oid)

			group['indicators'].forEach((child) => {
				indicators.push(child?._id?.$oid);
			});
		});

		setParentIndicators(parent);
		setIndicatorsToShow(indicators);
	}
	
	useEffect(() => {
		dispatch(indicators_progress_loading_true());
		dispatch(get_indicators_config({ scope: 4, reference: _ls.module }));
		dispatch(get_indicators_filters({ scope: 4, reference: _ls.module }));
		dispatch(get_route_cycle(route_id));
	}, []);

	useEffect(() => {
		if (route_cycle !== null) {
			let query = { route: route_id }
			
			if (!isEmpty(route_cycle)) {
				if (route_cycle?._id?.$oid !== cycle_id) {
					query.cycle = cycle_id;
				}
			}

			dispatch(get_indicators_progress(query));
			dispatch(get_org_reports({
				app: process.env.REACT_APP_ALIAS,
				organization: route_cycle?.organization?.$oid,
				workspace: route_cycle?.workspace?.$oid,
				module: route_cycle?.module?.$oid
			}));
		}
	}, [route_cycle]);
	
	useEffect(() => {
		if (indicators_filters.length > 0) {
			addIndicators();
		}
	}, [indicators_filters]);

	useEffect(() => {
		if (org_reports.length > 0) {
			const filteredOrgReports = org_reports.filter(report => 
				route_cycle?.organization?.$oid === report.organization.$oid && 
				route_cycle?.workspace?.$oid === report.workspace.$oid && 
				route_cycle?.module?.$oid === report.module.$oid
			);
			setOrgReports(filteredOrgReports);
		}
	}, [org_reports]);

	if (indicators_progress_loading) {
		return (
			<LoadingSpinner loadingMsg={t('indicators.loading')} />
		);
	}
	else {
		return (
			<Container className='mt-3 pb-5' fluid>
				<h2 className='text-center'>{t('indicators.title')}</h2>
				<RouteInfo />

				<Tabs
					tabBarExtraContent={<div style={{ borderBottom: '2px solid #1890ff' }} />}
					defaultActiveKey='1'
					centered
					items={[
						{
							key: '1',
							label: t('indicators.locationRatings'),
							children: (
								<Row>
									<Col lg={9} md={9} sm={12}>
										<IndicatorsTable
											cycleId={cycle_id}
											indicators_filters={indicators_filters}
											indicators={indicators_progress}
											indicatorsToShow={indicatorsToShow}
											orgReports={orgReports}
										/>
									</Col>
									<Col lg={3} md={3} sm={12}>
										<IndicatorsList
											indicators_filters={indicators_filters}
											parentIndicators={parentIndicators}
											setParentIndicators={setParentIndicators}
											indicatorsToShow={indicatorsToShow}
											setIndicatorsToShow={setIndicatorsToShow}
										/>
									</Col>
								</Row>
							)
						},
						{
							key: '2',
							label: t('indicators.missingLocations'),
							children: (
								<IndicatorsMissingLoc />
							)
						},
						{
							key: '3',
							label: t('indicators.retryLocations'),
							children: (
								<IndicatorsRetry />
							)
						},
						{
							key: '4',
							label: t('indicators.issueLocations'),
							children: (
								<IndicatorsIssues />
							)
						}
					]}
				/>
			</Container>
		);
	}
}

export default Indicators;
