import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Container, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Components
import OrgFilters from '../filters/OrgFilters';
import AreaFilters from '../filters/AreaFilters';
import CycleDatesInfo from '../utils/CycleDatesInfo';
import Graphics from '../overview/Graphics';
import TableData from '../overview/TableData';

// Actions
import { get_organizations, get_workspaces, get_modules, get_cycles, get_areas } from '../../actions/organizationActions';
import { get_monthly_graphic_data, get_daily_graphic_data, get_progress } from '../../actions/overviewActions';
import { get_indicators_config } from '../../actions/indicatorsActions';
import { get_org_reports } from '../../actions/reportsActions';

const Landing = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const _ls = { ...localStorage };

	const { user } = useSelector(state => state.auth);

	const { organizations, cycles } = useSelector(state => state.organization);

	const { org_reports } = useSelector(state => state.reports);

	const [orgFilters, setOrgFilters] = useState({ organization: '', workspace: '', module: '', cycle: '' });
	const [areaFilters, setAreaFilters] = useState({ region: '', zone: '', depot: '', group: '', route: '' });
	const [areaLabels, setAreaLabels] = useState({ region: '', zone: '', depot: '', group: '' });

	const [cycle, setCycle] = useState({});
	const [dayToSearch, setDayToSearch] = useState('');
	const [showStaffData, setShowStaffData] = useState(false);
	const [displayData, setDisplayData] = useState(false);

	const [orgReports, setOrgReports] = useState([]);

	useEffect(() => {
		dispatch(get_organizations());
	}, []);

	useEffect(() => {
		let _organization = '';

		if (organizations.length <= 1) {
			_organization = user.organization;
		}
		else {
			_organization = _ls?.organization;
		}
		
		setOrgFilters({
			...orgFilters,
			organization: _organization,
			workspace: _ls?.workspace || '',
			module: _ls?.module || '',
			cycle: _ls?.cycle || ''
		});
	}, [organizations]);

	useEffect(() => {
		// setDisplayData(false);
		if (orgFilters.organization !== '') {
			dispatch(get_workspaces({ organization: orgFilters.organization }));
			dispatch(get_modules({ organization: orgFilters.organization }));
		}

		if (orgFilters.workspace !== '' && orgFilters.module !== '') {
			dispatch(get_cycles({ workspace: orgFilters.workspace, module: orgFilters.module }));
		}
	}, [orgFilters]);

	useEffect(() => {
		if (orgFilters.module === '' || orgFilters.cycle === '') {
			setDisplayData(false);
		}
	}, [orgFilters]);

	useEffect(() => {
		if (orgFilters.cycle !== '') {
			dispatch(get_areas(orgFilters.workspace, orgFilters.cycle, areaFilters));
		}
	}, [orgFilters, areaFilters]);

	useEffect(() => {
		const day = moment(dayToSearch, 'YYYY-MM-DD');

		let dayDuration = {
			start: Math.trunc(day.startOf('day').valueOf() / 1000),
			end: Math.trunc(day.endOf('day').valueOf() / 1000)
		}

		if (dayToSearch !== '') {
			dispatch(get_daily_graphic_data(orgFilters, areaFilters, dayDuration));
		}
	}, [dayToSearch]);

	const handleRequests = () => {
		setDisplayData(true);

		let cycle = cycles.find(cycle => cycle._id.$oid === _ls?.cycle);
		setCycle(cycle);

		let cycleDuration = {
			start: cycle?.start?.$date / 1000,
			end: cycle?.end?.$date / 1000
		}

		let day = null;
		let dayDuration = {};

		if (cycle?.status === 5) { // cycle has ended
			day = moment(cycle?.end?.$date);
			dayDuration = {
				start: Math.trunc(day.startOf('day').valueOf() / 1000),
				end: Math.trunc(day.endOf('day').valueOf() / 1000)
			}
			setDayToSearch(moment(cycle?.end?.$date).format('YYYY-MM-DD'));
		}
		else { // cycle is available
			day = moment();
			dayDuration = {
				start: Math.trunc(day.startOf('day').valueOf() / 1000),
				end: Math.trunc(day.endOf('day').valueOf() / 1000)
			}
			setDayToSearch(moment().format('YYYY-MM-DD'));
		}

		dispatch(get_monthly_graphic_data(orgFilters, areaFilters, cycleDuration));
		dispatch(get_daily_graphic_data(orgFilters, areaFilters, dayDuration));

		dispatch(get_progress(true, orgFilters, areaFilters, showStaffData));
		dispatch(get_progress(false, orgFilters, areaFilters, showStaffData));
		dispatch(get_indicators_config({ scope: 4, reference: orgFilters.module }));

		dispatch(get_org_reports({
			app: process.env.REACT_APP_ALIAS,
			organization: orgFilters.organization,
			workspace: orgFilters.workspace,
			module: orgFilters.module
		}));
	}

	useEffect(() => {
		if (cycles.length > 0 && cycle) {
			handleRequests();
		}
	}, [areaFilters, showStaffData]);

	useEffect(() => {
		if (displayData && org_reports.length > 0) {
			const filteredOrgReports = org_reports.filter(report => 
				orgFilters.organization === report.organization.$oid && 
				orgFilters.workspace === report.workspace.$oid && 
				orgFilters.module === report.module.$oid
			);
			setOrgReports(filteredOrgReports);
		}
	}, [org_reports]);
	
	return (
		<Container className='mt-3 pb-5' fluid>
			<h2 className='text-center'>Intelligence</h2>
			<p className='text-center'>{t('landing.instruction')}</p>

			<OrgFilters
				orgFilters={orgFilters}
				setOrgFilters={setOrgFilters}
			/>
			<div className='d-grid gap-2 col-2 mx-auto text-center py-4'>
				<Button className='submit-btn' onClick={handleRequests}>
					{t('landing.search')}
				</Button>
			</div>

			{displayData
				?	<Fragment>
						<h4 className='text-center mt-4 mb-3'>{t('landing.filterBy')}</h4>

						{/* Area Filters */}
						<div className='text-center mb-2'><i className='bi bi-globe2 me-2'></i>{t('filters.areas')}</div>
						<AreaFilters
							areaFilters={areaFilters}
							setAreaFilters={setAreaFilters}
							areaLabels={areaLabels}
							setAreaLabels={setAreaLabels}
						/>
						<div className='d-flex align-items-center justify-content-center mb-3'>
							<Form.Check
								type='switch'
								id='custom-switch'
								label={t('filters.staff')}
								checked={showStaffData}
								onChange={() => setShowStaffData(!showStaffData)}
							/>
						</div>

						{/* Cycle dates info */}
						<CycleDatesInfo cycle={cycle} />

						{/* Bar charts */}
						<Graphics
							cycle={cycle}
							dayToSearch={dayToSearch} 
							setDayToSearch={setDayToSearch}
						/>

						{/* Table */}
						<TableData
							cycle={cycle}
							orgFilters={orgFilters}
							areaFilters={areaFilters}
							areaLabels={areaLabels}
							orgReports={orgReports}
						/>
					</Fragment>
				:	<img className='image' src={process.env.PUBLIC_URL + '/assets/intelligence-img.svg'} alt='Intelligence' />
			}
		</Container>
	);
}

export default Landing;
