import React from 'react';
import { useSelector } from 'react-redux';

// Packages
import { Card, Container, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const IndicatorsMissingLoc = () => {
	const { t } = useTranslation();

	const { missing_locations } = useSelector(state => state.indicators);

	return (
		<Container>
			<Card className='shadow mb-5 bg-body rounded card-border' style={{ height: '728px', overflowY: 'scroll' }}>
				<Table hover>
					<thead className='th-indicators'>
						<tr>
							<th>Id</th>
							<th>{t('missingLocations.storeName')}</th>
							<th>{t('indicatorsTable.storeCode')}</th>
						</tr>
					</thead>
					<tbody className='text-center'>
						{missing_locations.length > 0
							?	missing_locations.map((location, locationIdx) => (
									<tr style={{ height: '53px', verticalAlign: 'middle' }} key={locationIdx}>
										<td>{location?.location?._id?.$oid}</td>
										<td>{location?.location?.name}</td>
										<td>{location?.location?.code}</td>
									</tr>
								))
							:	<tr style={{ height: '53px', verticalAlign: 'middle' }}>
									<td colSpan={18}>{t('missingLocations.error')}</td>
								</tr>
						}
					</tbody>
				</Table>
			</Card>
		</Container>
	);
}

export default IndicatorsMissingLoc;
