import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Packages
import { Modal, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { get_indicators_rules } from '../../actions/indicatorsActions';

// Utils
import { createHexcolor, getColorContrast } from '../../utils/colorsHandler';
import find_indicator_ranking from '../../utils/find_indicator_ranking';

const RetryLocationModal = (props) => {
	const { showModal, onHide, locationInfo } = props;
	
	const _ls = { ...localStorage };
	
	const { t } = useTranslation();
	
	const dispatch = useDispatch();
	
	const { indicators_config, indicators_rules } = useSelector(state => state.indicators);

	useEffect(() => {
		dispatch(get_indicators_rules({ workspace: _ls.workspace }));
	}, [showModal]);

	return (
		<Modal
			show={showModal}
			onHide={onHide}
			backdrop='static'
			keyboard={false}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{locationInfo?.location?.name}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h4 className='text-center'>{t('indicators.title')}</h4>
				
				<p className='text-center my-0 fs-5'>{t('indicatorsTable.score')}</p>
				<p className='text-center fs-3'>{locationInfo?.score} / {indicators_config?.max}</p>

				<div className='accordion' id='indicatorsAccordion'>
					{locationInfo?.indicators?.map((group, groupIdx) => (
						<div
							key={groupIdx}
							className='accordion-item'
							style={{ borderBottom: groupIdx !== locationInfo?.indicators?.length - 1 ? 'none' : null }}
						>
							<h2 className='accordion-header'>
								<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target={`#collapse-${groupIdx}`} aria-expanded='true' aria-controls={`collapse-${groupIdx}`}
									style={{
										color: getColorContrast(group?.group?.color),
										backgroundColor: createHexcolor(group?.group?.color)
									}} 
								>
									{group?.group?.name}
								</button>
							</h2>
							<div id={`collapse-${groupIdx}`} className='accordion-collapse collapse' data-bs-parent='#indicatorsAccordion'>
								<div className='accordion-body'>
									<Table className='table-score' responsive='lg'>
										<thead className='th-indicators' style={{ border: '#415577 1px solid' }}>
											<tr>
												<th>{t('retryLocations.modal.indicator')}</th>
												<th>{t('retryLocations.modal.score')}</th>
												<th></th>
												<th>{t('retryLocations.modal.expected')}</th>
											</tr>
										</thead>
										<tbody className='text-center'>
											{group?.indicators?.length > 0
												?	group?.indicators.map((child, childIdx) => (
														child?.status === 2 &&
															<tr
																key={childIdx}
																style={{ verticalAlign: 'middle' }}
															>
																<td>{child?.name}</td>
																<td>{child?.score}</td>
																<td>/</td>
																<td>{find_indicator_ranking(indicators_rules, child?._id?.$oid)}</td>
															</tr>
													))
												:	<tr>
														<p className='text-center m-0'>{t('indicatorsList.error')}</p>
													</tr>
											}
										</tbody>
									</Table>
								</div>
							</div>
						</div>
					))}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Link
					style={{ textDecoration: 'none' }}
					to={`${process.env.REACT_APP_DISPLAY_URL}/locations/${locationInfo?.location?._id?.$oid}/payloads/${locationInfo?.payload?.$oid}`}
					target='_blank'
				>
					{t('retryLocations.modal.viewPayload')} <i className='bi bi-chevron-right ms-2 me-1'></i>
				</Link>
			</Modal.Footer>
		</Modal>		
	);
}

RetryLocationModal.propTypes = {
	showModal: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	locationInfo: PropTypes.object.isRequired
}

export default RetryLocationModal;
