import React from 'react';

// Packages
import {  Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend );

const BarChart = (props) => {
	const { dataset, month } = props;

	const _ls = { ...localStorage };

	const createDate = (epoch) => {
		let labelDate = '';
		
		if (month) { // grafica del mes
			let date = new Date(epoch);
			labelDate = date.toLocaleString(_ls.i18nextLng, { day: 'numeric', month: 'numeric'}).replace('/', '-');
		}
		else { // grafica de dias
			let date = new Date(epoch);
			labelDate = `${date.toLocaleString(_ls.i18nextLng, { hour: 'numeric', hour12: !_ls.i18nextLng.includes('es') })} ${_ls.i18nextLng.includes('es') ? 'Hrs.' : ''}`;
		}
		
		return labelDate;
	}
	
	const options = {
		responsive: true,
		plugins: {
			legend: { display: false }
		},
		scales: {
			x: {
				grid: { display: false }
			},
			y: {
				display: false,
				grid: { display: false }
			}
		},
		maintainAspectRatio: false,
	};
	
	const data = {
		labels: dataset.map(element => createDate(element.time)),
		datasets: [
			{
				data: dataset.map(element => Math.trunc(element.value)),
				backgroundColor: '#3e619b'
			}
		]
	};

	return (
		<Bar options={ options } data={ data }/>
	);
}

BarChart.propTypes = {
	dataset: PropTypes.array.isRequired,
	month: PropTypes.bool.isRequired
};

export default BarChart;
