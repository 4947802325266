import {
	ORGANIZATIONS_GET,
	ORGANIZATIONS_ERROR,
	ORG_INFO_GET,
	ORG_INFO_ERROR,
	WORKSPACES_GET,
	WORKSPACES_ERROR,
	MODULES_GET,
	MODULES_ERROR,
	CYCLES_GET,
	CYCLES_ERROR,
	AREAS_GET,
	AREAS_ERROR,
	ROUTE_CYCLE_GET
} from '../actions/types';

const initialState = {
	organizations: [],
	organization_info: {},
	workspaces: [],
	modules: [],
	cycles: [],
	areas: [],
	route_cycle: null,
	organization_errors: {}
}

export default function organizationReducer(state = initialState, action){
	switch(action.type){
		case ORGANIZATIONS_GET:
			return {
				...state,
				organizations: action.payload
			}
		case ORG_INFO_GET:
			return {
				...state,
				organization_info: action.payload
			}
		case WORKSPACES_GET:
			return {
				...state,
				workspaces: action.payload
			}
		case MODULES_GET:
			return {
				...state,
				modules: action.payload
			}
		case CYCLES_GET:
			return {
				...state,
				cycles: action.payload
			}
		case AREAS_GET:
			return {
				...state,
				areas: action.payload
			}
		case ROUTE_CYCLE_GET:
			return {
				...state,
				route_cycle: action.payload
			}
		case ORGANIZATIONS_ERROR:
		case ORG_INFO_ERROR:
		case WORKSPACES_ERROR:
		case MODULES_ERROR:
		case CYCLES_ERROR:
		case AREAS_ERROR:
			return {
				...state,
				organization_errors: {
					...state.organization_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
