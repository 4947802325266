import {
	INDICATORS_CONFIG_GET,
	INDICATORS_CONFIG_ERROR,
	INDICATORS_FILTERS_GET,
	INDICATORS_FILTERS_ERROR,
	INDICATORS_PROGRESS_LOADING_TRUE,
	INDICATORS_PROGRESS_GET,
	INDICATORS_PROGRESS_ERROR,
	INDICATORS_RULES_GET,
	INDICATORS_RULES_ERROR
} from '../actions/types';

const initialState = {
	indicators_config: {},
	indicators_filters: [],
	indicators_progress_loading: false,
	indicators_progress: [],
	missing_locations: [],
	issue_locations: [],
	retry_locations: [],
	indicators_rules: [],
	indicators_errors: {}
}

export default function indicatorsReducer(state = initialState, action) {
	switch(action.type) {
		case INDICATORS_CONFIG_GET:
			delete state.indicators_errors.indicators_config
			return {
				...state,
				indicators_config: action.payload
			}
		case INDICATORS_FILTERS_GET:
			delete state.indicators_errors.indicators_filters
			return {
				...state,
				indicators_filters: action.payload
			}
		case INDICATORS_PROGRESS_LOADING_TRUE:
			return {
				...state,
				indicators_progress_loading: true
			}
		case INDICATORS_PROGRESS_GET:
			delete state.indicators_errors.indicators_progress
			return {
				...state,
				indicators_progress_loading: false,
				indicators_progress: action.payload.filter(i => i?.record?.record_type === 1),
				missing_locations: action.payload.filter(i => i?.record === null),
				issue_locations: action.payload.filter(i => i?.record?.record_type === 2),
				retry_locations: action.payload.filter(i => i?.record?.record_type === 3)
			}
		case INDICATORS_RULES_GET:
			delete state.indicators_errors.indicators_rules
			return {
				...state,
				indicators_rules: action.payload
			}
		case INDICATORS_CONFIG_ERROR:
		case INDICATORS_FILTERS_ERROR:
		case INDICATORS_PROGRESS_ERROR:
		case INDICATORS_RULES_ERROR:
			return {
				...state,
				indicators_errors: {
					...state.indicators_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
