import Axios from 'axios';
import i18next from 'i18next';

import {
	ORGANIZATIONS_GET,
	ORGANIZATIONS_ERROR,
	ORG_INFO_GET,
	ORG_INFO_ERROR,
	WORKSPACES_GET,
	WORKSPACES_ERROR,
	MODULES_GET,
	MODULES_ERROR,
	CYCLES_GET,
	CYCLES_ERROR,
	AREAS_GET,
	AREAS_ERROR,
	ROUTE_CYCLE_GET
} from './types';

import create_query_params from '../utils/create_query_params';
import { getCycleStatusDesc } from '../utils/getStatusDesc';
import { epochToLocalDate } from '../utils/datesHandler';

export const get_organizations = () => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + '/api/organizations';

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ORGANIZATIONS_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ORGANIZATIONS_ERROR,
			payload: { type: 'organizations', msg: err.message }
		})
	})
}

export const get_organization_info = (org_id) => dispatch => {
	// console.log(org_id)
	let url = process.env.REACT_APP_SERVER_URL + `/api/organizations/${org_id}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ORG_INFO_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ORG_INFO_ERROR,
			payload: { type: 'org_info', msg: err.message }
		})
	})
}

export const get_workspaces = (filters) => dispatch => {
	// console.log(filters)
	let url = process.env.REACT_APP_SERVER_URL + '/api/organizations/workspaces?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: WORKSPACES_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORKSPACES_ERROR,
			payload: { type: 'wokspaces', msg: err.message }
		})
	})
}

export const get_modules = (filters) => dispatch => {
	// console.log(filters)
	let url = process.env.REACT_APP_SERVER_URL + '/api/modules?module_type=2&';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: MODULES_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: MODULES_ERROR,
			payload: { type: 'modules', msg: err.message }
		})
	})
}

const updateCyclesName = (cycles) => {
	cycles.sort((prev, next)=> (prev.date.$date < next.date.$date ? 1 : -1));

	cycles.forEach((cycle) => {
		cycle.name = `${cycle.name} - ${i18next.t('cycle.selectStart')} ${epochToLocalDate(cycle.start.$date)} - ${i18next.t('cycle.selectEnd')} ${epochToLocalDate(cycle.end.$date)} - ${getCycleStatusDesc(cycle.status)}`;
	})

	return cycles;
}

export const get_cycles = (filters) => dispatch => {
	// console.log(filters)
	let url = process.env.REACT_APP_SERVER_URL + '/api/cycles/data?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: CYCLES_GET,
			payload: updateCyclesName(res.data)
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: CYCLES_GET,
			payload: []
		})
		dispatch({
			type: CYCLES_ERROR,
			payload: { type: 'cycles', msg: err.message }
		})
	})
}

const getAreaType = (areaFilters) => {
	let area = '';  // if there's no area filters

	if (areaFilters.region !== '') {
		area = 'region';
	}
	if (areaFilters.zone !== '') {
		area = 'zone';
	}
	if (areaFilters.depot !== '') {
		area = 'depot';
	}

	return area;
}

const createAreaFilters = (workspace, cycle, areaFilters) => {
	let newAreaFilters = {};

	const isEmpty = Object.values(areaFilters).every(f => f === '');

	if (isEmpty) { // if there's no area filters
		newAreaFilters = {
			workspace: workspace,
			cycle: cycle
		}
	}
	else {
		let area = getAreaType(areaFilters);

		newAreaFilters = {
			cycle: cycle,
			[area]: areaFilters[area]
		}
	}

	return newAreaFilters;
}

export const get_areas = (workspace, cycle, areaFilters = {}) => dispatch => {
	let filters = createAreaFilters(workspace, cycle, areaFilters);
	
	let url = process.env.REACT_APP_SERVER_URL + '/api/areas/overview?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: AREAS_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: AREAS_ERROR,
			payload: { type: 'areas', msg: err.message }
		})
	})
}

export const get_route_cycle = (route_id) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + `/api/routes/${route_id}/cycle`;

	Axios.get(url)
	.then((res) => {
		dispatch({
			type: ROUTE_CYCLE_GET,
			payload: res.data
		})
	}).catch((err) => {
		dispatch({
			type: ROUTE_CYCLE_GET,
			payload: {}
		})
	})
}
