import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Container, Spinner, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { download_report } from '../../actions/reportsActions';

// Utils
import { cycleDaysInfo } from '../../utils/datesHandler';
import errorExists from '../../utils/errorExists';

const TableData = (props) => {
	const { cycle, orgFilters, areaFilters, areaLabels, orgReports } = props;

	const { t } = useTranslation();
	
	const dispatch = useDispatch();
	
	const _ls = { ...localStorage };
	
	const { indicators_overview } = useSelector(state => state.overview);

	const { indicators_config } = useSelector(state => state.indicators);

	const { download_report_loading, reports_errors } = useSelector(state => state.reports);

	const [daysInfo, setDaysInfo] = useState({ workingDays: 0, currentDays: 0, remainingDays: 0 });

	const headerStyles = {
		height: '105px'
	}

	const headerCellTextStyles = {
		backgroundColor: '#415577',
		color: 'white',
		fontWeight: 'bold',
	}

	useEffect(() => {
		const [workingDays, currentDays, remainingDays] = cycleDaysInfo(cycle?.start?.$date, cycle?.end?.$date);

		setDaysInfo({
			...daysInfo,
			workingDays: workingDays,
			currentDays: currentDays,
			remainingDays: remainingDays
		});
	}, [cycle]);

	const viewIndicatorsInfo = (row) => {
		window.open(`/cycle/${cycle?._id?.$oid}/route/${row?.area_id?.$oid}/indicators`, '_blank');
	}

	return (
		<Container className='mt-5 mb-5'>
			<Table style={{ minWidth: 500 }}>
				<thead className='align-middle text-center'>
					<tr style={headerStyles}>
						<th style={headerCellTextStyles}>{t('progressTable.area')}</th>
						<th style={headerCellTextStyles}>{t('progressTable.available')}</th>
						<th style={headerCellTextStyles}>{t('progressTable.work')}</th>
						<th style={headerCellTextStyles}>{t('progressTable.good')}</th>
						<th style={headerCellTextStyles}>{t('progressTable.mixed')}</th>
						<th style={headerCellTextStyles}>{t('progressTable.bad')}</th>
						{/* <th style={headerCellTextStyles}>{t('progressTable.fraud')}</th> */}
						<th style={headerCellTextStyles}>{t('progressTable.retry')}</th>
						<th style={headerCellTextStyles}>{t('progressTable.total')}</th>
						<th style={headerCellTextStyles}>{t('progressTable.scoreAverage')}</th>
						<th style={headerCellTextStyles}>{t('progressTable.max')}</th>
					</tr>
				</thead>
				<tbody className='align-middle text-center'>
					{indicators_overview?.length === 0
						?	<tr>
								<td colSpan='17'>{t('progressTable.error')}</td>
							</tr>
						:	indicators_overview.map((row, i) => (
								<tr key={i} 
									style={{
										height: '53px',
										backgroundColor: row.name === 'TOTAL' ? '#e0e0e0': '#ffffff',
										cursor: (row.name !== 'TOTAL' && row.area_type === 6) && 'pointer'
									}}
									onClick={() => (row.name !== 'TOTAL' && row.area_type === 6) && viewIndicatorsInfo(row)}
								>
									<td>{row.name}</td>
									<td style={{ width: 160 }}>{row?.available?.toLocaleString(_ls.i18nextLng) || 0}</td>
									<td style={{ width: 160 }}>{row?.work?.toLocaleString(_ls.i18nextLng) || 0}</td>
									<td style={{ width: 160 }}>{row?.good?.toLocaleString(_ls.i18nextLng) || 0}</td>
									<td style={{ width: 160 }}>{row?.mixed?.toLocaleString(_ls.i18nextLng) || 0}</td>
									<td style={{ width: 160 }}>{row?.bad?.toLocaleString(_ls.i18nextLng) || 0}</td>
									{/* <td style={{ width: 160 }}>{row?.fraud?.toLocaleString(_ls.i18nextLng) || 0}</td> */}
									<td style={{ width: 160 }}>{row?.retry?.toLocaleString(_ls.i18nextLng) || 0}</td>
									<td style={{ width: 160 }}>{row?.total?.toLocaleString(_ls.i18nextLng) || 0}</td>
									<td style={{ width: 160 }}>{row?.average_score.toFixed(2).toLocaleString(_ls.i18nextLng) || 0}</td>
									<td style={{ width: 160 }}>{(row?.available * (indicators_config?.max || 0))?.toLocaleString(_ls.i18nextLng) || 0}</td>
								</tr>
							))
					}
				</tbody>
				{orgReports.length > 0 && 
					<tfoot style={{ backgroundColor: 'white' }}>
						<tr style={{ height: '70px' }}>
							<td colSpan='11'>
								{orgReports.map((report) => (
									<Button key={report._id.$oid}
										className={'fw-bold me-5 ' + (errorExists(reports_errors, 'download_report') && download_report_loading.report_id === report._id.$oid 
											? 'danger-btn' : 'submit-btn'
										)}
										onClick={() => { dispatch(download_report(report, { orgFilters, areaFilters, areaLabels })) }}
									>
										{download_report_loading.loading && download_report_loading.report_id === report._id.$oid
											?	<div className='d-flex align-items-center justify-content-center' style={{ width: '150px' }}>
													<Spinner animation='border' variant='light' style={{ height: '24px', width: '24px' }} />
												</div>
											:	errorExists(reports_errors, 'download_report') && download_report_loading.report_id === report._id.$oid
												?	<div style={{ width: '150px' }}>
														Error <i className='bi bi-x-lg bi-bold ms-2'></i>
													</div>
												:	<div>
														{t('reports.download')} {report.name} <i className='bi bi-download bi-bold-download ms-2'></i>
													</div>
										}
									</Button>
								))}
							</td>
						</tr>
					</tfoot>
				}
			</Table>
		</Container>
	);
}

TableData.propTypes = {
	cycle: PropTypes.object.isRequired,
	orgFilters: PropTypes.object.isRequired,
	areaFilters: PropTypes.object.isRequired,
	orgReports: PropTypes.array.isRequired
};

export default TableData;
