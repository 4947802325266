import { combineReducers } from 'redux';

// Reducers
import authReducer from './authReducer';
import organizationReducer from './organizationReducer';
import areasReducer from './areasReducer';
import overviewReducer from './overviewReducer';
import indicatorsReducer from './indicatorsReducer';
import reportsReducer from './reportsReducer';

export default combineReducers ({
	auth: authReducer,
	organization: organizationReducer,
	areas: areasReducer,
	overview: overviewReducer,
	indicators: indicatorsReducer,
	reports: reportsReducer
});
