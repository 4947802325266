// Alert
export const ALERT_SET = 'ALERT_SET';
export const ALERT_REMOVE = 'ALERT_REMOVE';

// User
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const USER_INIT = 'USER_INIT';
export const USER_INIT_CORRECT = 'USER_INIT_CORRECT';
export const USER_INIT_ERROR = 'USER_INIT_ERROR';

// Organization
export const ORGANIZATIONS_GET = 'ORGANIZATIONS_GET';
export const ORGANIZATIONS_ERROR = 'ORGANIZATIONS_ERROR';
export const ORG_INFO_GET = 'ORG_INFO_GET';
export const ORG_INFO_ERROR = 'ORG_INFO_ERROR';

export const WORKSPACES_GET = 'WORKSPACES_GET';
export const WORKSPACES_ERROR = 'WORKSPACES_ERROR';

export const MODULES_GET = 'MODULES_GET';
export const MODULES_ERROR = 'MODULES_ERROR';

export const CYCLES_GET = 'CYCLES_GET';
export const CYCLES_ERROR = 'CYCLES_ERROR';

export const AREAS_GET = 'AREAS_GET';
export const AREAS_ERROR = 'AREAS_ERROR';

export const ROUTE_CYCLE_GET = 'ROUTE_CYCLE_GET';

// Areas
export const ROUTE_INFO_GET = 'ROUTE_INFO_GET';
export const ROUTE_INFO_ERROR = 'ROUTE_INFO_ERROR';

// Overview
export const MONTHLY_GRAPHIC_GET = 'MONTHLY_GRAPHIC_GET';
export const MONTHLY_GRAPHIC_ERROR = 'MONTHLY_GRAPHIC_ERROR';
export const DAILY_GRAPHIC_GET = 'DAILY_GRAPHIC_GET';
export const DAILY_GRAPHIC_ERROR = 'DAILY_GRAPHIC_ERROR';

export const PROGRESS_GET = 'PROGRESS_GET';
export const PROGRESS_ERROR = 'PROGRESS_ERROR';
export const INDICATORS_OVERVIEW_GET = 'INDICATORS_OVERVIEW_GET';
export const INDICATORS_OVERVIEW_ERROR = 'INDICATORS_OVERVIEW_ERROR';

export const ROUTE_PROGRESS_GET = 'ROUTE_PROGRESS_GET';
export const ROUTE_PROGRESS_ERROR = 'ROUTE_PROGRESS_ERROR';

// Indicators
export const INDICATORS_CONFIG_GET = 'INDICATORS_CONFIG_GET';
export const INDICATORS_CONFIG_ERROR = 'INDICATORS_CONFIG_ERROR';

export const INDICATORS_FILTERS_GET = 'INDICATORS_FILTERS_GET';
export const INDICATORS_FILTERS_ERROR = 'INDICATORS_FILTERS_ERROR';

export const INDICATORS_PROGRESS_LOADING_TRUE = 'INDICATORS_PROGRESS_LOADING_TRUE';
export const INDICATORS_PROGRESS_GET = 'INDICATORS_PROGRESS_GET';
export const INDICATORS_PROGRESS_ERROR = 'INDICATORS_PROGRESS_ERROR';

export const INDICATORS_RULES_GET = 'INDICATORS_RULES_GET';
export const INDICATORS_RULES_ERROR = 'INDICATORS_RULES_ERROR';

// Reports
export const ORG_REPORTS_GET = 'ORG_REPORTS_GET';
export const ORG_REPORTS_GET_ERROR = 'ORG_REPORTS_GET_ERROR';

export const REPORT_DOWNLOAD_LOADING_TRUE = 'REPORT_DOWNLOAD_LOADING_TRUE';
export const REPORT_DOWNLOAD_LOADING_FALSE = 'REPORT_DOWNLOAD_LOADING_FALSE';
export const REPORT_DOWNLOAD = 'REPORT_DOWNLOAD';
export const REPORT_DOWNLOAD_ERROR = 'REPORT_DOWNLOAD_ERROR';
