import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Packages
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import BarChart from '../utils/BarChart';
import Error from '../utils/Error';

// Utils
import errorExists from '../../utils/errorExists';

const Graphics = (props) => {
	const { cycle, dayToSearch, setDayToSearch } = props;

	const { t } = useTranslation();
	
	const { monthly_graphic_data, daily_graphic_data, overview_errors } = useSelector(state => state.overview);

	const [today, setToday] = useState('');
	const [minDate, setMinDate] = useState('');
	const [maxDate, setMaxDate] = useState('');

	const createDate = (epoch) => {
		let date = new Date(epoch);

		let year = date.getFullYear();
		let month = date.getMonth() + 1;
		let day = date.getDate();

		let newDate = year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);

		return newDate;
	}

	useEffect(() => {
		setToday(createDate(new Date().getTime()));
		setMinDate(createDate(cycle?.start?.$date));
		setMaxDate(createDate(cycle?.end?.$date));
	}, [cycle]);

	return ( 
		<Container className='mt-4 mb-5' fluid>
			<h4 className='text-center mb-4'>{t('graphics.title')}</h4>
			<Row>
				<Col className='text-center' lg={6} md={6} sm={12}>
					<div className='bar-chart'>
						{errorExists(overview_errors, 'monthly_graphic')
							?	<Error type='warning' msg={t('graphics.errors.monthlyGraphic')} />
							:	<BarChart dataset={monthly_graphic_data} month={true}/>
						}
					</div>
					<h5 className='mt-2'>{t('graphics.monthlyGraphic')}</h5>
				</Col>
				<Col className='text-center' lg={6} md={6} sm={12}>
					<div className='bar-chart'>
						{errorExists(overview_errors, 'daily_graphic') || daily_graphic_data.length === 0
							?	<Error type='warning' msg={t('graphics.errors.dailyGraphic')} />
							:	<BarChart dataset={daily_graphic_data} month={false}/>
						}
					</div>
					<div className='d-flex row justify-content-center align-items-center mt-2'>
						<h5 className='col-lg-3 col-md-6 col-sm-6 col-xs-12 m-0 pe-0'>{t('graphics.dailyGraphic')}</h5>
						<input className='col-lg-2 col-md-6 col-sm-6 col-xs-12' type='date' value={dayToSearch}
							min={minDate} max={cycle?.status === 5 ? maxDate : today}
							onChange={(e) => setDayToSearch(e.target.value)}
						/>
					</div>
				</Col>
			</Row>
		</Container>
	);
}

Graphics.propTypes = {
	cycle: PropTypes.object,
	dayToSearch: PropTypes.string.isRequired,
	setDayToSearch: PropTypes.func.isRequired
};

export default Graphics;
