import Axios from 'axios';

import {
	ORG_REPORTS_GET,
	ORG_REPORTS_GET_ERROR,
	REPORT_DOWNLOAD_LOADING_TRUE,
	REPORT_DOWNLOAD_LOADING_FALSE,
	REPORT_DOWNLOAD,
	REPORT_DOWNLOAD_ERROR
} from './types';

import { getAreaType } from './overviewActions'

const create_report_query_params = (filters) => {
	let query_params = '';

	Object.keys(filters).forEach((f) => {
		query_params = query_params + `${f}="${filters[f]}"&`;
	})

	return query_params;
}

const create_query_params = (filters) => {
	let query_params = '';

	Object.keys(filters).forEach((f) => {
		query_params = query_params + (filters[f] !== '' ? `${f}=${filters[f]}&` : '');
	})

	return query_params;
}

const createNewOrgFilters = (orgFilters = {}) => {
	return {
		server: process.env.REACT_APP_RUNTIME === 'Test' || process.env.REACT_APP_RUNTIME === 'Development' ? 'Test' : 'Production',
		organizacion: orgFilters.organization,
		workspace: orgFilters.workspace,
		modulo: orgFilters.module,
		ciclo: orgFilters.cycle
	}
}

const createNewAreaFilters = (areaLabels = {}) => {
	let areaFiltersKeys = ['region', 'territorio', 'bodega', 'grupo', 'ruta'];

	let newAreaFilters = {};
	Object.keys(areaLabels).map((key, idx) => {
		if (areaLabels[key] !== '') {
			newAreaFilters[areaFiltersKeys[idx]] = areaLabels[key]
		}
	})

	return newAreaFilters;
}

export const get_org_reports = (filters) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + '/api/reports/data?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ORG_REPORTS_GET,
			payload: res.data
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ORG_REPORTS_GET_ERROR,
			payload: { type: 'organization_reports', msg: Object.values(err.response.data)[0] }
		});
	})
}

export const download_report = (reportConfig = {}, reportFilters = {}) => dispatch => {
	dispatch({
		type: REPORT_DOWNLOAD_LOADING_TRUE,
		payload: reportConfig._id.$oid
	});

	if (reportConfig.create_tab) {
		// Reports from GCP Cloud Functions
		const newOrgFilters = createNewOrgFilters(reportFilters.orgFilters);
		const newAreaFilters = createNewAreaFilters(reportFilters.areaLabels);
		const token = Axios.defaults.headers.common['Authorization'].split(' ');

		let url = `${reportConfig.action}?`;
		let query = create_report_query_params({
			...newOrgFilters,
			...newAreaFilters,
			report: reportConfig._id.$oid,
			token: token[1]
		});
		url += query;
		
		window.open(url);

		dispatch({
			type: REPORT_DOWNLOAD_LOADING_FALSE,
			payload: ''
		});
	}
	else {
		// Reports from Reports Service
		let { area_type, area } = getAreaType(reportFilters.areaFilters);

		let url = `${reportConfig.action}?`;
		let query = create_query_params({
			...reportFilters.orgFilters,
			...reportFilters.areaFilters,
			area_type: area_type,
			report: reportConfig._id.$oid
		});
		url += query;
		let filename = `${reportConfig.name.toLowerCase().replace(' ', '_')}-${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}.csv`;

		Axios.request({
			url: url,
			method: 'GET',
			responseType: 'blob',
		}).then(({ data }) => {
			dispatch({
				type: REPORT_DOWNLOAD_LOADING_FALSE,
				payload: reportConfig._id.$oid
			});

			const downloadURL = window.URL.createObjectURL(new Blob([ data ]));
			const link = document.createElement('a');
			link.href = downloadURL;
			link.setAttribute('download', `${filename}`);
			document.body.appendChild(link);
			link.click();
			link.remove();
		}).catch((err) => {
			dispatch({
				type: REPORT_DOWNLOAD_ERROR,
				payload: { type: 'download_report', msg: Object.values(err.response.data)[0] }
			});

			setTimeout(() => {
				dispatch({ type: REPORT_DOWNLOAD });
			}, 3000);

			dispatch({
				type: REPORT_DOWNLOAD_LOADING_FALSE,
				payload: reportConfig._id.$oid
			});
		})
	}
}
