const checkPacificIndicators = (indicatorName = '', region = '') => {
	const pacificIndicators = ['Enfriador reseteado', 'Presencia colas 600, 1500, 2500', 'Pacifico Lona Propuesta Valor', 'Puntos Extra Rack Refresco'];
	let showIndicator = true;
	
	if (pacificIndicators.includes(indicatorName)) {
		if (region === 'PACIFICO') {
			showIndicator = true;
		}
		else {
			showIndicator = false;
		}
	}

	return showIndicator;
}

export default checkPacificIndicators;
