import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Packages
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// Actions
import { get_route_info } from '../../actions/areasActions';
import { get_route_progress } from '../../actions/overviewActions';

const RouteInfo = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { cycle_id, route_id } = useParams();

	const { route_progress } = useSelector(state => state.overview);

	const { route_info } = useSelector(state => state.areas);

	useEffect(() => {
		dispatch(get_route_info(route_id));
		dispatch(get_route_progress(cycle_id, route_id));
	}, []);

	return (
		<Container>
			<Card className='shadow mb-5 bg-body rounded card-border'>
				<Card.Body>
					<Card.Title className='text-center' as='h5'>{t('filters.route')}: {route_info?.code}</Card.Title>
					<Card.Subtitle className='mb-2 text-center text-muted' as='p'><strong>Id:</strong> {route_info?._id?.$oid}</Card.Subtitle>
					<hr/>
					<Container className='p-0'>
						<Row>
							<Card.Title className='text-center' as='h5'>{t('filters.areas')}</Card.Title>
						</Row>
						<Row>
							<Col className='text-center'><strong>{t('filters.region')}:</strong> {route_info?.region?.name}</Col>
							<Col className='text-center'><strong>{t('filters.zone')}:</strong> {route_info?.zone?.name}</Col>
							<Col className='text-center'><strong>{t('filters.depot')}:</strong> {route_info?.depot?.name}</Col>
							<Col className='text-center'><strong>{t('filters.group')}:</strong> {route_info?.group?.name}</Col>
						</Row>
						<hr/>
						<Row>
							<Card.Title className='text-center' as='h5'>{t('progressTable.progress')}</Card.Title>
						</Row>
						<Row>
							<Col className='text-center'>
								<strong>{t('progressTable.available')}:</strong> {route_progress?.available}
							</Col>
							<Col className='text-center'>
								<strong>{t('progressTable.work')}:</strong> {route_progress?.work}
							</Col>
							<Col className='text-center'>
								<strong>{t('progressTable.issues')}:</strong> {route_progress?.issues}
							</Col>
							<Col className='text-center'>
								<strong>{t('progressTable.notWorked')}:</strong> {route_progress?.available - (route_progress?.work + route_progress?.issues)}
							</Col>
							<Col className='text-center'>
								<strong>{t('progressTable.total')}:</strong> {route_progress?.work + route_progress?.issues}
							</Col>
						</Row>
					</Container>
				</Card.Body>
			</Card>
		</Container>
	);
}

export default RouteInfo;
