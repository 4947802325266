import React from 'react';

// Packages
import { Container, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

const LoadingSpinner = (props) => {
	const { loadingMsg } = props;

	return (
		<Container className='d-flex align-items-center justify-content-center position-absolute top-50 start-50 translate-middle'>
			<p className='spinner-text'>{loadingMsg}</p>
			<div className='spinner-margin'>
				<Spinner animation='border' style={{ width: '2.5rem', height: '2.5rem', color: '#004982' }} />
			</div>
		</Container>
	);
}

LoadingSpinner.propTypes = {
	loadingMsg: PropTypes.string.isRequired
}

export default LoadingSpinner;
