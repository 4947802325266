import Axios from 'axios';

import {
	INDICATORS_CONFIG_GET,
	INDICATORS_CONFIG_ERROR,
	INDICATORS_FILTERS_GET,
	INDICATORS_FILTERS_ERROR,
	INDICATORS_PROGRESS_LOADING_TRUE,
	INDICATORS_PROGRESS_GET,
	INDICATORS_PROGRESS_ERROR,
	INDICATORS_RULES_GET,
	INDICATORS_RULES_ERROR
} from './types';

import create_query_params from '../utils/create_query_params';

export const get_indicators_config = (filters) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + '/api/indicators/configuration/data?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: INDICATORS_CONFIG_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: INDICATORS_CONFIG_ERROR,
			payload: { type: 'indicators_config', msg: err.message }
		})
	})
}

export const get_indicators_filters = (filters) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + '/api/indicators/data?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: INDICATORS_FILTERS_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: INDICATORS_FILTERS_ERROR,
			payload: { type: 'indicators_filters', msg: err.message }
		})
	})
}

export const indicators_progress_loading_true = () => dispatch => {
	dispatch({
		type: INDICATORS_PROGRESS_LOADING_TRUE
	});
}

export const get_indicators_progress = (filters) => dispatch => {
	dispatch(indicators_progress_loading_true());

	let url = process.env.REACT_APP_SERVER_URL + '/api/indicators/overview?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: INDICATORS_PROGRESS_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: INDICATORS_PROGRESS_ERROR,
			payload: { type: 'indicators_progress', msg: err.message }
		})
	})
}

export const get_indicators_rules = (filters) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + '/api/indicators/rules/data?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: INDICATORS_RULES_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: INDICATORS_RULES_ERROR,
			payload: { type: 'indicators_rules', msg: err.message }
		})
	})
}
