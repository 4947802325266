import React, { Fragment, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';

// Packages
import { Accordion, Form, Card, ListGroup, AccordionContext } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Utils
import { createHexcolor, getColorContrast } from '../../utils/colorsHandler';
import checkPacificIndicators from '../../utils/checkPacificIndicators';

const CustomToggle = ({ children, eventKey, color, callback }) => {
	const { activeEventKey } = useContext(AccordionContext);

	const decoratedOnClick = useAccordionButton(
		eventKey,
		() => callback && callback(eventKey),
	);

	const isCurrentEventKey = activeEventKey === eventKey;

	return (
		<button
			type="button"
			style={{
				background: 'none',
				border: 'none',
				color: getColorContrast(color),
				fontWeight: isCurrentEventKey ? 'bold' : 'normal'
			}}
			onClick={decoratedOnClick}
		>
			{children}
		</button>
	);
}

const IndicatorsList = (props) => {
	const { indicators_filters, parentIndicators, setParentIndicators, indicatorsToShow, setIndicatorsToShow } = props;

	const { t } = useTranslation();

	const { route_info } = useSelector(state => state.areas);
	
	const checkboxRef = useRef([]);

	const allItemsIn = (parentIdx, childrenInd, indicators) => {
		let allIn = false;

		const noCommonElements = childrenInd.every(element => !indicators.includes(element?._id?.$oid));

		if (noCommonElements) {
			// Ningún elemento del arreglo 1 (hijos) está presente en el arreglo 2 (show).
			if (checkboxRef.current[parentIdx]) {
				checkboxRef.current[parentIdx].indeterminate = false;
			}
			allIn = false;
		}
		else {
			// Al menos un elemento del arreglo 1 (hijos) está presente en el arreglo 2 (show).
			const allItemsInArray = childrenInd.every(element => indicators.includes(element?._id?.$oid));
			if (allItemsInArray) {
				// Absolutamente todos los elementos del arreglo 1 (hijos) están presentes en el arreglo 2 (show).
				if (checkboxRef.current[parentIdx]) {
					checkboxRef.current[parentIdx].indeterminate = false;
				}
				allIn = true;
			}
			else {
				// No todos los elementos del arreglo 1 (hijos) están presentes en el arreglo 2 (show).
				if (checkboxRef.current[parentIdx]) {
					checkboxRef.current[parentIdx].indeterminate = true;
				}
				allIn = false;
			}
		}

		return allIn;
	}

	const checkParent = (parentIdx, parentId) => {
		let indicators = [...indicatorsToShow];
		let parent = [...parentIndicators];
		
		const indicatorFound = indicators_filters.find(g => g['group']?._id?.$oid === parentId);
		let indicatorsToSearch = [];
		indicatorFound.indicators.forEach((child) => {
			indicatorsToSearch.push(child?._id?.$oid);
		})
		
		if (parent.find(i => i === parentId)) {
			// quita indicadores de la lista
			parent = parent.filter((p) => p !== parentId);
			indicators = indicators.filter((elemento) => !indicatorsToSearch.includes(elemento));
		}
		else {
			// añade indicadores de la lista
			parent.push(parentId);
			indicatorsToSearch.forEach((ind) => {
				if (!indicators.find(i => i === ind)) {
					indicators.push(ind);
				}
			})
		}

		allItemsIn(parentIdx, indicatorFound?.indicators, indicators);
		setIndicatorsToShow(indicators);
		setParentIndicators(parent);
	}

	const addIndicator = (parentIdx, parentId, childId) => {
		let indicators = [...indicatorsToShow];
		let parent = [...parentIndicators];

		if (indicators.find(i => i === childId)) {
			indicators = indicators.filter((elemento) => elemento !== childId);
		}
		else {
			indicators.push(childId)
		}

		const indicatorFound = indicators_filters.find(g => g['group']?._id?.$oid === parentId);
		if (allItemsIn(parentIdx, indicatorFound.indicators, indicators)) {
			parent.push(parentId);
		}
		else {
			parent = parent.filter((p) => p !== parentId);
		}

		setIndicatorsToShow(indicators);
		setParentIndicators(parent);
	}

	return (
		<Card className='shadow mb-5 bg-body rounded card-border'>
			<Card.Body>
				<Card.Title>{t('indicatorsList.header')}</Card.Title>
				<ListGroup className='mt-3' variant='flush' style={{ height: '65vh', overflowY: 'scroll' }}>
					{indicators_filters.length > 0
						?	<Fragment>
								{indicators_filters.map((group, idx) => (
									<Accordion key={idx} >
											<Card.Header style={{
												borderRadius: 0,
												borderBottom: 'none',
												backgroundColor: createHexcolor(group['group'].color)
											}}>
												<div className='d-flex align-items-center'>
													<Form.Check 
														type='checkbox'
														id={group['group']._id?.$oid}
														ref={el => checkboxRef.current[idx] = el}
														checked={parentIndicators.find(p => p === group['group']._id?.$oid) || false}
														onChange={() => checkParent(idx, group['group']._id?.$oid)}
													/>
													<CustomToggle eventKey={idx} color={group['group'].color}>{group['group'].name}</CustomToggle>
												</div>
											</Card.Header>
											<Accordion.Collapse eventKey={idx}>
												<Card.Body>
													{group['indicators'].length > 0
														?	<Fragment>
																{group['indicators'].map((child, childIdx) => (
																	<Fragment key={childIdx}>
																		{(child?.status === 2 && checkPacificIndicators(child.name, route_info?.region?.name)) &&
																			<Form.Check
																				type='checkbox'
																				id={child?._id?.$oid}
																				label={child.name}
																				checked={indicatorsToShow.find(item => item === child?._id?.$oid) || false}
																				onChange={() => addIndicator(idx, group['group']._id?.$oid, child?._id?.$oid)}
																			/>
																		}
																	</Fragment>
																))}
															</Fragment>
														:	<p className='text-center m-0'>{t('indicatorsList.error')}</p>
													}
												</Card.Body>
											</Accordion.Collapse>
									</Accordion>
								))}
							</Fragment>
						: <p className='text-center mt-2'>{t('indicatorsList.error')}</p>
					}
				</ListGroup>
			</Card.Body>
		</Card>
	);
}

IndicatorsList.propTypes = {
	indicators_filters: PropTypes.array.isRequired,
	parentIndicators: PropTypes.array.isRequired,
	setParentIndicators: PropTypes.func.isRequired,
	indicatorsToShow: PropTypes.array.isRequired,
	setIndicatorsToShow: PropTypes.func.isRequired
}

export default IndicatorsList;
