export const SPANISH_TRANSLATIONS = {
	menu: {
		account: 'Cuenta',
		return: 'Regresar al menú',
		logOut: 'Cerrar sesión'
	},
	landing: {
		instruction: 'Usa los filtros para poder visualizar estadísticas relacionadas con los indicadores.',
		search: 'Buscar',
		filterBy: 'Filtrar por:'
	},

	// FILTERS
	filters: {
		organization: 'Organización',
		workspace: 'Workspace',
		module: 'Módulo',
		submodule: 'Submódulo',
		cycle: 'Ciclo',
		areas: 'Áreas',
		region: 'Región',
		zone: 'Zona',
		depot: 'Depot',
		group: 'Grupo',
		route: 'Ruta',
		staff: 'Usuarios Supervisores'
	},
	select: {
		region: 'Cant. de Regiones:',
		zone: 'Cant. de Zonas:',
		depot: 'Cant. de Depots:',
		group: 'Cant. de Grupos:',
		route: 'Cant. de Rutas:'
	},

	// CYCLE INFO
	cycle: {
		selectStart: 'Inicio',
		selectEnd: ' Fin',
		start: 'Inicio de ciclo:',
		end: ' Fin de ciclo:',
		workingDays: 'Días laborables:',
		currentDays: 'Días transcurridos:',
		remainingDays: 'Días restantes:'
	},

	// GRAPHICS
	graphics: {
		title: 'Gráficas',
		monthlyGraphic: 'Indicadores por día',
		dailyGraphic: 'Indicadores por hora',
		errors: {
			monthlyGraphic: 'No hay payloads del ciclo seleccionado.',
			dailyGraphic: 'No hay payloads del día seleccionado.',
		}
	},

	// TABLE DATA
	progressTable: {
		progress: 'Progreso',
		area: 'Área',
		available: 'Por evaluar',
		work: 'Calificados',
		good: 'Correctos',
		mixed: 'Regulares',
		bad: 'Incorrectos',
		fraud: 'Fraude',
		retry: 'Repetir',
		total: 'Avance Total',
		scoreAverage: 'Promedio de Calificación',
		max: 'Avance Máximo',
		issues: 'Incidencias',
		notWorked: 'Sin realizar',
		error: 'Sin información'
	},
	reports: {
		download: 'Descargar'
	},

	// INDICATORS
	indicators: {
		title: 'Indicadores',
		loading: 'Cargando Indicadores',
		locationRatings: 'Calificados',
		missingLocations: 'Faltantes',
		retryLocations: 'Repetir',
		issueLocations: 'Incidencias'
	},
	indicatorsList: {
		header: 'Lista de Indicadores',
		error: 'No hay indicadores disponibles'
	},
	indicatorsTable: {
		storeCode: 'Código',
		score: 'Calificación Total',
		error: 'No hay establecimientos calificados'
	},
	missingLocations: {
		storeName: 'Nombre',
		error: 'No hay establecimientos faltantes'
	},
	retryLocations: {
		date: 'Fecha',
		days: 'Días',
		modal: {
			indicator: 'Indicador',
			score: 'Calificación',
			expected: 'Esperado',
			viewPayload: 'Ver payload'
		},
		error: 'No hay establecimientos para repetir'
	},
	issueLocations: {
		issueType: 'Tipo de la Incidencia',
		error: 'No hay incidencias'
	},

	// STATUS DESCRIPTIONS
	statusDesc: {
		cycle: {
			none: 'None',
			created: 'Creado',
			starting: 'Empezando',
			available: 'Disponible',
			waiting: 'Esperando',
			completed: 'Completado',
			removed: 'Eliminado'
		},
		issue: {
			none: 'None',
			closed: 'Cerrado',
			closed_down: 'Cerrado permanentemente',
			access: 'Acceso',
			process: 'No se evaluó',
			products: 'No tiene productos',
			custom: 'Personalizada'
		}
	}
};
