import React from 'react';

// Packages
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Components
import Header from './components/main/Header';
import Landing from './components/main/Landing';
import Footer from './components/main/Footer';
import NotFound from './components/main/NotFound';
import Authentication from './components/main/Authentication';
import PrivateRoute from './router/PrivateRoute';
import Indicators from './components/indicators/Indicators';

// Redux
import { Provider } from 'react-redux';
import store from './store';

// Actions
import { user_token_check } from './actions/authActions';

user_token_check (store);

const App = () => {
	return (
		<Provider store= { store }>
			<Router>
				<div className='App'>
					<Header />

					<Routes>
						<Route path='/auth' element={ <Authentication /> } exact />

						<Route element={ <PrivateRoute /> } exact>
							<Route path='/' element={ <Landing /> } exact />
							<Route path='/cycle/:cycle_id/route/:route_id/indicators' element={ <Indicators /> } exact />
						</Route>

						{/* Other */}
						<Route path='*' element={ <NotFound /> } />
					</Routes>

					<Footer/>
				</div>
			</Router>
		</Provider>
	);
}

export default App;
