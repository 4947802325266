import React from 'react';
import { useSelector } from 'react-redux';

// Packages
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import SelectButton from '../utils/SelectButton';

const OrgFilters = (props) => {
	const { orgFilters, setOrgFilters } = props;

	const { t } = useTranslation();

	const { organizations, workspaces, modules, cycles } = useSelector(state => state.organization);

	const onChangeOrgFilters = (e, name) => {
		let filters = {...orgFilters};
		let keys = Object.keys(filters);
		
		let idx = keys.indexOf(name);
		for (let index = idx; index < keys.length; index++) {
			const key = keys[index];
			filters[key] = '';
			localStorage.setItem(key, '');
		}

		if (e !== null){
			filters[name] = e.value;
			localStorage.setItem(name, e.value);
		}

		setOrgFilters(filters);
	}

	return (
		<Container className='justify-content-center mb-3'>
			<Row className='justify-content-center'>
				{organizations.length > 1 &&
					<Col className='my-2' lg={6} md={6} sm={12}>
						<Row>
							<Form.Label className='text-center'><i className='bi bi-house me-2'></i>{t('filters.organization')}</Form.Label>
						</Row>
						<SelectButton
							options={organizations}
							name='organization'
							value={orgFilters.organization === '' ? null : orgFilters.organization}
							onChange={onChangeOrgFilters}
							disable={false}
						/>
					</Col>
				}
				<Col className='my-2' lg={6} md={6} sm={12}>
					<Row>
						<Form.Label className='text-center'><i className='bi bi-list me-2'></i>{t('filters.workspace')}</Form.Label>
					</Row>
					<SelectButton
						options={orgFilters.organization === '' ? [] : workspaces}
						name='workspace'
						value={orgFilters.workspace === '' ? null : orgFilters.workspace}
						onChange={onChangeOrgFilters}
						disable={orgFilters.organization === ''}
					/>
				</Col>
			</Row>
			<Row className='justify-content-center'>
				<Col className='my-2' lg={6} md={6} sm={12}>
					<Row>
						<Form.Label className='text-center'><i className='bi bi-box2 me-2'></i>{t('filters.module')}</Form.Label>
					</Row>
					<SelectButton
						options={orgFilters.workspace === '' ? [] : modules}
						name='module'
						value={orgFilters.module === '' ? null : orgFilters.module}
						onChange={onChangeOrgFilters}
						disable={orgFilters.workspace === ''}
					/>
				</Col>
				<Col className='my-2' lg={6} md={6} sm={12}>
					<Row>
						<Form.Label className='text-center'><i className='bi bi-arrow-repeat me-2'></i>{t('filters.cycle')}</Form.Label>
					</Row>
					<SelectButton
						options={orgFilters.module === '' ? [] : cycles}
						name='cycle'
						value={orgFilters.cycle === '' ? null : orgFilters.cycle}
						onChange={onChangeOrgFilters}
						disable={orgFilters.module === ''}
					/>
				</Col>
			</Row>
		</Container>
	);
}

OrgFilters.propTypes = {
	orgFilters: PropTypes.object.isRequired,
	setOrgFilters: PropTypes.func.isRequired
};

export default OrgFilters;