import {
	MONTHLY_GRAPHIC_GET,
	MONTHLY_GRAPHIC_ERROR,
	DAILY_GRAPHIC_GET,
	DAILY_GRAPHIC_ERROR,
	PROGRESS_GET,
	PROGRESS_ERROR,
	INDICATORS_OVERVIEW_GET,
	INDICATORS_OVERVIEW_ERROR,
	ROUTE_PROGRESS_GET,
	ROUTE_PROGRESS_ERROR
} from '../actions/types';

const initialState = {
	monthly_graphic_data: [],
	daily_graphic_data: [],
	progress: [],
	indicators_overview: [],
	route_progress: {},
	overview_errors: {}
}

export default function overviewReducer(state = initialState, action){
	switch(action.type){
		case MONTHLY_GRAPHIC_GET:
			delete state.overview_errors.monthly_graphic
			return {
				...state,
				monthly_graphic_data: action.payload
			}
		case DAILY_GRAPHIC_GET:
			delete state.overview_errors.daily_graphic
			return {
				...state,
				daily_graphic_data: action.payload
			}
		case PROGRESS_GET:
			delete state.overview_errors.progress
			return {
				...state,
				progress: action.payload
			}
		case INDICATORS_OVERVIEW_GET:
			delete state.overview_errors.indicators_overview
			return {
				...state,
				indicators_overview: action.payload
			}
		case ROUTE_PROGRESS_GET:
			delete state.overview_errors.route_progress
			return {
				...state,
				route_progress: action.payload
			}
		case MONTHLY_GRAPHIC_ERROR:
		case DAILY_GRAPHIC_ERROR:
		case PROGRESS_ERROR:
		case INDICATORS_OVERVIEW_ERROR:
		case ROUTE_PROGRESS_ERROR:
			return {
				...state,
				overview_errors: {
					...state.overview_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
