export const ENGLISH_TRANSLATIONS = {
	menu: {
		account: 'Account',
		return: 'Return to menu',
		logOut: 'Log Out'
	},
	landing: {
		instruction: 'Use the filters to visualize statistics related to indicators.',
		search: 'Search',
		filterBy: 'Filter By:'
	},
	
	// FILTERS
	filters: {
		organization: 'Organization',
		workspace: 'Workspace',
		module: 'Module',
		submodule: 'Submodule',
		cycle: 'Cycle',
		areas: 'Areas',
		region: 'Region',
		zone: 'Zone',
		depot: 'Depot',
		group: 'Group',
		route: 'Route',
		staff: 'Staff Users'
	},
	select: {
		region: 'Qty. of Regions:',
		zone: 'Qty. of Zones:',
		depot: 'Qty. of Depots:',
		group: 'Qty. of Groups:',
		route: 'Qty. of Routes:'
	},

	// CYCLE INFO
	cycle: {
		selectStart: 'Start',
		selectEnd: ' End',
		start: 'Start of cycle:',
		end: ' End of cycle:',
		workingDays: 'Working days:',
		currentDays: 'Days elapsed:',
		remainingDays: 'Remaining days:'
	},

	// GRAPHICS
	graphics: {
		title: 'Graphics',
		monthlyGraphic: 'Indicators per day',
		dailyGraphic: 'Indicators per hour',
		errors: {
			monthlyGraphic: 'There are no payloads of the selected cycle.',
			dailyGraphic: 'There are no payloads of the selected date.',
		}
	},

	// TABLE DATA
	progressTable: {
		progress: 'Progress',
		area: 'Area',
		available: 'Available',
		work: 'Scored',
		good: 'Good',
		mixed: 'Regular',
		bad: 'Bad',
		fraud: 'Fraud',
		retry: 'Retry',
		total: 'Total Progress',
		scoreAverage: 'Average Score',
		max: 'Maximum Advance',
		issues: 'Issues',
		notWorked: 'Not  worked',
		error: 'No information'
	},
	reports: {
		download: 'Download'
	},

	// INDICATORS
	indicators: {
		title: 'Indicators',
		loading: 'Loading Indicators',
		locationRatings: 'Location Scores',
		missingLocations: 'Missing Locations',
		retryLocations: 'Retry Locations',
		issueLocations: 'Issues Locations'
	},
	indicatorsList: {
		header: 'Indicators List',
		error: 'No indicators available'
	},
	indicatorsTable: {
		storeCode: 'Code',
		score: 'Total Score',
		error: 'No location scores'
	},
	missingLocations: {
		storeName: 'Name',
		error: 'No missing locations'
	},
	retryLocations: {
		date: 'Date',
		days: 'Days',
		modal: {
			indicator: 'Indicator',
			score: 'Score',
			expected: 'Expected',
			viewPayload: 'View payload'
		},
		error: 'No locations to retry'
	},
	issueLocations: {
		issueType: 'Issue Type',
		error: 'No issues'
	},

	// STATUS DESCRIPTIONS
	statusDesc: {
		cycle: {
			none: 'None',
			created: 'Created',
			starting: 'Starting',
			available: 'Available',
			waiting: 'Waiting',
			completed: 'Completed',
			removed: 'Removed'
		},
		issue: {
			none: 'None',
			closed: 'Closed',
			closed_down: 'Closed down',
			access: 'Access',
			process: 'Process',
			products: 'Products',
			custom: 'Custom'
		}
	}
};
