import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// Actions
import { auth_token_remove } from '../../actions/authActions';
import { get_organization_info } from '../../actions/organizationActions';

const Header = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const user = useSelector(state => state.auth.user);

	const { organization_info } = useSelector(state => state.organization);

	useEffect(() => {
		dispatch(get_organization_info(user.organization));
	}, []);

	const handleLogout = (e) => {
		e.preventDefault();
		dispatch(auth_token_remove());
	}

	return (
		<Navbar className='navbar' expand='lg' variant='dark'>
			<Container fluid>
				<Navbar.Brand href='/'>
					<img className='d-inline-block align-items-center me-2' height={'30px'} alt='Percepthor Logo'
						src={process.env.PUBLIC_URL + '/assets/percepthor-logo.png'}
					/>
					Percepthor - {organization_info.name}
				</Navbar.Brand>
				<Navbar.Toggle aria-controls='navbarScroll' />
				<Navbar.Collapse id='navbarScroll'>
					<Nav className='ms-auto'>
						<NavDropdown 
							title={<Fragment><i className='bi bi-person-circle fs-5 me-2'></i>{user.username}</Fragment>}
							id='userDropdown' 
							align='end'
							active
						>
							<NavDropdown.Item href={process.env.REACT_APP_LOGIN + '/account'}>
								<i className='bi bi-person-circle me-2'></i>{t('menu.account')}
							</NavDropdown.Item>
							<NavDropdown.Item href={process.env.REACT_APP_LOGIN + '/links'}>
								<i className='bi bi-grid me-2'></i>{t('menu.return')}
							</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item onClick={(e) => handleLogout(e)}>
								<i className='bi bi-box-arrow-right me-2'></i>{t('menu.logOut')}
							</NavDropdown.Item>
						</NavDropdown>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default Header;
