import {
	ROUTE_INFO_GET,
	ROUTE_INFO_ERROR
} from '../actions/types';

const initialState = {
	route_info: {},
	areas_errors: {}
}

export default function areasReducer(state = initialState, action){
	switch(action.type) {
		case ROUTE_INFO_GET:
			delete state.areas_errors.route_info
			return {
				...state,
				route_info: action.payload
			}
		case ROUTE_INFO_ERROR:
			return {
				...state,
				areas_errors: {
					...state.areas_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
