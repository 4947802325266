import React from 'react';
import { useSelector } from 'react-redux';

// Packages
import { Card, Container, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// Utils
import { getIssueTypeDesc } from '../../utils/getStatusDesc';

const IndicatorsIssues = () => {
	const { t } = useTranslation();

	const { issue_locations } = useSelector(state => state.indicators);

	const goToDisplay = (locationRecord) => {
		window.open(`${process.env.REACT_APP_DISPLAY_URL}/locations/${locationRecord?.location?.$oid}/issues/${locationRecord?.issue?.$oid}`, '_blank');
	}

	return (
		<Container>
			<Card className='shadow mb-5 bg-body rounded card-border' style={{ height: '728px', overflowY: 'scroll' }}>
				<Table hover>
					<thead className='th-indicators'>
						<tr>
							<th>Id</th>
							<th>{t('missingLocations.storeName')}</th>
							<th>{t('indicatorsTable.storeCode')}</th>
							<th>{t('issueLocations.issueType')}</th>
						</tr>
					</thead>
					<tbody className='text-center'>
						{issue_locations.length > 0
							?	issue_locations.map((location, locationIdx) => (
									<tr 
										key={locationIdx}
										style={{ height: '53px', verticalAlign: 'middle', cursor: 'pointer' }}
										onClick={() => goToDisplay(location?.record)}
									>
										<td>{location?.location?._id?.$oid}</td>
										<td>{location?.location?.name}</td>
										<td>{location?.location?.code}</td>
										<td>{getIssueTypeDesc(location?.record?.issue_type)}</td>
									</tr>
								))
							:	<tr style={{ height: '53px', verticalAlign: 'middle' }}>
									<td colSpan={18}>{t('issueLocations.error')}</td>
								</tr>
						}
					</tbody>
				</Table>
			</Card>
		</Container>
	);
}

export default IndicatorsIssues;
