import React from 'react';
import { useSelector } from 'react-redux';

// Packages
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import SelectButton from '../utils/SelectButton';

const AreaFilters = (props) => {
	const { areaFilters, setAreaFilters, areaLabels, setAreaLabels } = props;

	const { t } = useTranslation();

	const { areas } = useSelector(state => state.organization);

	const onChangeAreaFilters = (e, name) => {
		let filters = {...areaFilters};
		let labels = {...areaLabels};
		let keys = Object.keys(filters);
		
		if (e === null){
			let idx = keys.indexOf(name);
			for (let index = idx; index < keys.length; index++) {
				const key = keys[index];
				filters[key] = '';
				labels[key] = '';
				localStorage.setItem(key, '');
			}
		}
		else {
			filters[name] = e.value;
			labels[name] = e.label;
			localStorage.setItem(name, e.value);
		}

		setAreaFilters(filters);
		setAreaLabels(labels);
	}

	return (
		<Container className='d-flex row align-items-center justify-content-center mb-4' fluid>
			<Row>
				<Col lg={3} md={6} sm={12}>
					<Row>
						<Col className='text-center mt-2' lg={3}>
							<Form.Label>{t('filters.region')}</Form.Label>
						</Col>
						<Col lg={9}>
							<SelectButton
								options={'regions' in areas ? areas.regions : []}
								name='region'
								value={areaFilters.region === '' ? null : areaFilters.region}
								onChange={onChangeAreaFilters}
								disable={false}
								areaFilters={true}
							/>
						</Col>
					</Row>
				</Col>
				<Col lg={3} md={6} sm={12}>
					<Row>
						<Col className='text-center mt-2' lg={3}>
							<Form.Label>{t('filters.zone')}</Form.Label>
						</Col>
						<Col lg={9}>
							<SelectButton
								options={'zones' in areas ? areas.zones : []}
								name='zone'
								value={areaFilters.zone === '' ? null : areaFilters.zone}
								onChange={onChangeAreaFilters}
								disable={false}
								areaFilters={true}
							/>
						</Col>
					</Row>
				</Col>
				<Col lg={3} md={6} sm={12}>
					<Row>
						<Col className='text-center mt-2' lg={3}>
							<Form.Label>{t('filters.depot')}</Form.Label>
						</Col>
						<Col lg={9}>
							<SelectButton
								options={'depots' in areas ? areas.depots : []}
								name='depot'
								value={areaFilters.depot === '' ? null : areaFilters.depot}
								onChange={onChangeAreaFilters}
								disable={false}
								areaFilters={true}
							/>
						</Col>
					</Row>
				</Col>
				<Col lg={3} md={6} sm={12}>
					<Row>
						<Col className='text-center mt-2' lg={3}>
							<Form.Label>{t('filters.group')}</Form.Label>
						</Col>
						<Col lg={9}>
							<SelectButton
								options={'groups' in areas ? areas.groups : []}
								name='group'
								value={areaFilters.group === '' ? null : areaFilters.group}
								onChange={onChangeAreaFilters}
								disable={false}
								areaFilters={true}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
}

AreaFilters.propTypes = {
	areaFilters: PropTypes.object.isRequired,
	setAreaFilters: PropTypes.func.isRequired,
	areaLabels: PropTypes.object.isRequired,
	setAreaLabels: PropTypes.func.isRequired
};

export default AreaFilters;
