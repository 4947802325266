import i18next from 'i18next';

// CYCLE_STATUS_NONE = 0
// CYCLE_STATUS_CREATED = 1
// CYCLE_STATUS_STARTING = 2
// CYCLE_STATUS_AVAILABLE = 3
// CYCLE_STATUS_WAITING = 4
// CYCLE_STATUS_COMPLETED = 5
// CYCLE_STATUS_REMOVED = 6
const getCycleStatusDesc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = i18next.t('statusDesc.cycle.none');      break;
		case 1:   desc = i18next.t('statusDesc.cycle.created');   break;
		case 2:   desc = i18next.t('statusDesc.cycle.starting');  break;
		case 3:   desc = i18next.t('statusDesc.cycle.available'); break;
		case 4:   desc = i18next.t('statusDesc.cycle.waiting');   break;
		case 5:   desc = i18next.t('statusDesc.cycle.completed'); break;
		case 6:   desc = i18next.t('statusDesc.cycle.removed');   break;
		default:  desc = '';                                      break;
	}

	return desc;
}

// ISSUE_TYPE_NONE = 0
// ISSUE_TYPE_CLOSED = 1
// ISSUE_TYPE_CLOSED_DOWN = 2
// ISSUE_TYPE_ACCESS = 3
// ISSUE_TYPE_PROCESS = 4
// ISSUE_TYPE_PRODUCTS = 5
// ISSUE_TYPE_CUSTOM = 6
const getIssueTypeDesc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = i18next.t('statusDesc.issue.none');        break;
		case 1:   desc = i18next.t('statusDesc.issue.closed');      break;
		case 2:   desc = i18next.t('statusDesc.issue.closed_down'); break;
		case 3:   desc = i18next.t('statusDesc.issue.access');      break;
		case 4:   desc = i18next.t('statusDesc.issue.process');     break;
		case 5:   desc = i18next.t('statusDesc.issue.products');    break;
		case 6:   desc = i18next.t('statusDesc.issue.custom');      break;
		default:  desc = '';                                        break;
	}

	return desc;
}

export { getCycleStatusDesc, getIssueTypeDesc };
