import React, { useState, useEffect } from 'react';

// Packages
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const SelectButton = (props) => {
	const { options, name, value, onChange, disable, areaFilters } = props;

	const { t } = useTranslation();

	const [selectOptions, setSelectOptions] = useState([]);

	useEffect(() => {
		let optsArray = [];

		options.forEach((opt) => {
			if (opt === null || opt === undefined) return;

			let obj = {};
			obj.value = opt._id !== undefined ? opt._id.$oid : opt.value;
			obj.label = name !== 'submodule' ? opt.name : opt.title;
			optsArray.push(obj);
		});

		if (areaFilters) {
			const groupedOptions = [
				{
					label: `${t(`select.${name}`)} ${optsArray.length}`,
					options: optsArray
				}
			];
			setSelectOptions(groupedOptions);
		}
		else {
			setSelectOptions(optsArray);
		}
	}, [options]);

	return (
		<Select
			classNamePrefix='select'
			placeholder='Select...'
			noResultsText='No options'
			maxMenuHeight={170}
			options={selectOptions}
			value={value !== null ? selectOptions.find(opt => opt.value === value) : null}
			onChange={(e) => onChange(e, name)}
			isDisabled={disable}
			isClearable={true}
			isSearchable={true}
			theme={(selectTheme) => ({
				...selectTheme,
				colors: {
					...selectTheme.colors,
					primary50: undefined,
				},
			})}
		/>
	);
}

SelectButton.propTypes = {
	options: PropTypes.array.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.any,
	onChange: PropTypes.func.isRequired,
	disable: PropTypes.bool.isRequired,
	areaFilters: PropTypes.bool
};

export default SelectButton;
