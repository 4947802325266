import Axios from 'axios';

import {
	ROUTE_INFO_GET,
	ROUTE_INFO_ERROR
} from './types';

export const get_route_info = (route_id) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + `/api/routes/${route_id}/data`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ROUTE_INFO_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ROUTE_INFO_ERROR,
			payload: { type: 'route_info', msg: err.message }
		})
	})
}
