import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Card, Spinner, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { download_report } from '../../actions/reportsActions';

// Utils
import { createHexcolor, getColorContrast } from '../../utils/colorsHandler';
import checkPacificIndicators from '../../utils/checkPacificIndicators';
import errorExists from '../../utils/errorExists';
import isEmpty from '../../utils/isEmpty';

const IndicatorsTable = (props) => {
	const { cycleId, indicators_filters, indicators, indicatorsToShow, orgReports } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();
	
	const { route_info } = useSelector(state => state.areas);

	const { download_report_loading, reports_errors } = useSelector(state => state.reports);

	const [orgFilters, setOrgFilters] = useState({ organization: '', workspace: '', module: '', cycle: '' });
	const [areaFilters, setAreaFilters] = useState({ region: '', zone: '', depot: '', group: '', route: '' });
	const [areaLabels, setAreaLabels] = useState({ region: '', zone: '', depot: '', group: '', route: '' });

	useEffect(() => {
		if (!isEmpty(route_info)) {
			fillFilters(route_info)
		}
	}, [route_info]);

	const fillFilters = (route_info = {}) => {
		setOrgFilters({
			organization: route_info?.organization?.$oid,
			workspace: route_info?.workspace?.$oid,
			module: route_info?.module?.$oid,
			cycle: cycleId
		});

		setAreaFilters({
			region: route_info?.region?._id?.$oid,
			zone: route_info?.zone?._id?.$oid,
			depot: route_info?.depot?._id?.$oid,
			group: route_info?.group?._id?.$oid,
			route: route_info?._id?.$oid
		});
		
		setAreaLabels({
			region: route_info?.region?.name,
			zone: route_info?.zone?.name,
			depot: route_info?.depot?.name,
			group: route_info?.group?.name,
			route: route_info?._id?.$oid
		});
	}

	const locationIndicatorInfo = (group, indicator, locationIndicators) => {
		const groupId = group?.group?._id?.$oid;
		const indicatorId = indicator?._id?.$oid;

		if (locationIndicators) {
			var locationIndicatorFound = locationIndicators.find(li => li?.group?._id?.$oid === groupId);
			
			if (locationIndicatorFound) {
				var indicatorFound = locationIndicatorFound?.indicators.find(i => i?._id?.$oid === indicatorId);
				
				return indicatorFound?.score !== undefined ? indicatorFound?.score : '#N/A';
			}
		}
		else {
			return '-';
		}
	}

	const goToDisplay = (location_id, payload_id) => {
		window.open(`${process.env.REACT_APP_DISPLAY_URL}/locations/${location_id}/payloads/${payload_id}`, '_blank');
	}

	return (
		<div className='card shadow mb-5 bg-body rounded card-border ind-table-card'>
			<div className='ind-table'>
				<Table hover responsive='lg'>
					<thead className='th-indicators'>
						<tr>
							<th>{t('indicatorsTable.storeCode')}</th>
							{indicators_filters.map((group, idx) => (
								<Fragment key={idx}>
									{group.indicators.map((child) => {
										if (child?.status === 2 && checkPacificIndicators(child.name, route_info?.region?.name)) {
										if (indicatorsToShow.find(i => i === child?._id?.$oid)) {
											return <th key={child?._id?.$oid} 
												style={{
													backgroundColor: createHexcolor(group['group'].color),
													color: getColorContrast(group['group'].color)
												}}
											>{child.name}</th>
										}
									}
										
										return null;
									})}
								</Fragment>
							))}
							<th>{t('indicatorsTable.score')}</th>
						</tr>
					</thead>
					<tbody className='text-center'>
						{indicators.length > 0
							?	indicators.map((location, locationIdx) => (
									(location.indicators.length > 0 && location.score > 0) && (
										<tr style={{ height: '53px', verticalAlign: 'middle' }} key={locationIdx}>
											<td>{location?.location?.code}</td>
											{indicators_filters.map((group, groupIdx) => (
												<Fragment key={groupIdx}>
													{group.indicators.map((child) => {
														if (child?.status === 2 && checkPacificIndicators(child.name, route_info?.region?.name)) {
															if (indicatorsToShow.find(i => i === child?._id?.$oid)) {
																return (
																	<td
																		key={child?._id?.$oid}
																		style={{ cursor: 'pointer' }}
																		onClick={() => goToDisplay(location?.location?._id?.$oid, location?.payload?.$oid)}
																	>
																		{locationIndicatorInfo(group, child, location.indicators)}
																	</td>
																)
															}
														}
														return null;
													})}
												</Fragment>
											))}
											<td>{location?.score}</td>
										</tr>
									)
								))
							:	<tr style={{ height: '53px', verticalAlign: 'middle' }}>
									<td colSpan={18}>{t('indicatorsTable.error')}</td>
								</tr>
						}
					</tbody>
				</Table>
			</div>
			<div className='ind-table-button'>
				{orgReports.map((report) => (
					<Button key={report._id.$oid}
						className={'fw-bold me-5 ' + (errorExists(reports_errors, 'download_report') && download_report_loading.report_id === report._id.$oid 
							? 'danger-btn' : 'submit-btn'
						)}
						onClick={() => { dispatch(download_report(report, { orgFilters, areaFilters, areaLabels })) }}
					>
						{download_report_loading.loading && download_report_loading.report_id === report._id.$oid
							?	<div className='d-flex align-items-center justify-content-center' style={{ width: '150px' }}>
									<Spinner animation='border' variant='light' style={{ height: '24px', width: '24px' }} />
								</div>
							:	errorExists(reports_errors, 'download_report') && download_report_loading.report_id === report._id.$oid
								?	<div style={{ width: '150px' }}>
										Error <i className='bi bi-x-lg bi-bold ms-2'></i>
									</div>
								:	<div>
										{t('reports.download')} {report.name} <i className='bi bi-download bi-bold-download ms-2'></i>
									</div>
						}
					</Button>
				))}
			</div>
		</div>
	);
}

IndicatorsTable.propTypes = {
	cycleId: PropTypes.string.isRequired,
	indicators_filters: PropTypes.array.isRequired,
	indicators: PropTypes.array.isRequired,
	indicatorsToShow: PropTypes.array.isRequired,
	orgReports: PropTypes.array.isRequired
}
 
export default IndicatorsTable;
