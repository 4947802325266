import Axios from 'axios';

import {
	MONTHLY_GRAPHIC_GET,
	MONTHLY_GRAPHIC_ERROR,
	DAILY_GRAPHIC_GET,
	DAILY_GRAPHIC_ERROR,
	PROGRESS_GET,
	PROGRESS_ERROR,
	INDICATORS_OVERVIEW_GET,
	INDICATORS_OVERVIEW_ERROR,
	ROUTE_PROGRESS_GET,
	ROUTE_PROGRESS_ERROR
} from './types';

import create_query_params from '../utils/create_query_params';
import createProgressData from '../utils/createProgressData';

export const getAreaType = (areaFilters) => {
	let area_type = '1', area = '';  // if there's no area filters

	if (areaFilters.region !== '') {
		area_type = '2';
		area = 'region';
	}
	if (areaFilters.zone !== '') {
		area_type = '3';
		area = 'zone';
	}
	if (areaFilters.depot !== '') {
		area_type = '4';
		area = 'depot';
	}
	if (areaFilters.group !== '') {
		area_type = '5';
		area = 'group';
	}

	return { area_type, area };
}

const createGraphicFilters = (orgFilters, areaFilters, cycleDuration) => {
	let graphicFilters = {};

	const isEmpty = Object.values(areaFilters).every(f => f === '');

	if (isEmpty) { // if there's no area filters
		graphicFilters = {
			...cycleDuration,
			workspace: orgFilters.workspace,
			data_type: '3',
			module: orgFilters.module,
		}
	}
	else {
		let { area_type, area } = getAreaType(areaFilters);

		graphicFilters = {
			...cycleDuration,
			data_type: '3',
			area_type: area_type,
			area: areaFilters[area],
			module: orgFilters.module,
		}
	}

	return graphicFilters;
}

export const get_monthly_graphic_data = (orgFilters, areaFilters, cycleDuration) => dispatch => {
	let filters = createGraphicFilters(orgFilters, areaFilters, cycleDuration);
	filters.bucket_size = '86400000';

	let url = process.env.REACT_APP_SERVER_URL + '/api/time/data?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: MONTHLY_GRAPHIC_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: MONTHLY_GRAPHIC_ERROR,
			payload: {type: 'monthly_graphic', msg: err.message}
		})
	})
}

export const get_daily_graphic_data = (orgFilters, areaFilters, cycleDuration) => dispatch => {
	let filters = createGraphicFilters(orgFilters, areaFilters, cycleDuration);
	filters.bucket_size = '3600000';

	let url = process.env.REACT_APP_SERVER_URL + '/api/time/data?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: DAILY_GRAPHIC_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: DAILY_GRAPHIC_ERROR,
			payload: {type: 'daily_graphic', msg: err.message}
		})
	})
}

export const get_progress = (indicators, orgFilters, areaFilters, showStaffData) => dispatch => {
	let { area_type, area } = getAreaType(areaFilters);
	
	let filters = {
		...orgFilters,
		...(indicators && { indicators: true }),
		area_type: area_type, 
		staff: showStaffData
	}

	if (area !== '') {
		filters[area] = areaFilters[area];
	}

	let url = process.env.REACT_APP_SERVER_URL + '/api/data/overview?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		if (indicators) {
			res.data.sort((a, b) => a.name.localeCompare(b.name));
			
			// Returns an array of objects with indicators progress
			dispatch({
				type: INDICATORS_OVERVIEW_GET,
				payload: res.data.length > 0 ? createProgressData(res.data) : res.data
			})
		}
		else {
			// Returns an array of objects with the progress data for each area
			dispatch({
				type: PROGRESS_GET,
				payload: res.data.progress.data
			})
		}
	}).catch((err) => {
		// console.log(err);
		if (indicators) {
			dispatch({
				type: INDICATORS_OVERVIEW_ERROR,
				payload: {type: 'indicators_overview', msg: err.message}
			})
		}
		else {
			dispatch({
				type: PROGRESS_ERROR,
				payload: {type: 'progress', msg: err.message}
			})
		}
	})
}

export const get_route_progress = (cycle_id, route_id) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + `/api/routes/${route_id}/progress?single=true&cycle=${cycle_id}`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ROUTE_PROGRESS_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ROUTE_PROGRESS_ERROR,
			payload: { type: 'route_progress', msg: err.message }
		})
	})
}
