import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Packages
import { Card, Container, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// Components
import RetryLocationModal from './RetryLocationModal';

// Utils
import { epochToLocalDate, cycleDaysInfo } from '../../utils/datesHandler';

const IndicatorsRetry = () => {
	const { t } = useTranslation();

	const { retry_locations } = useSelector(state => state.indicators);

	const [showModal, setShowModal] = useState(false);
	const [locationInfo, setLocationInfo] = useState({});

	const displayLocationInfo = (locationInfo) => {
		setShowModal(true);
		setLocationInfo(locationInfo);
	}

	return (
		<Container>
			<RetryLocationModal
				showModal={showModal}
				onHide={() => setShowModal(false)}
				locationInfo={locationInfo}
			/>

			<Card className='shadow mb-5 bg-body rounded card-border' style={{ height: '728px', overflowY: 'scroll' }}>
				<Table hover>
					<thead className='th-indicators'>
						<tr>
							<th>Id</th>
							<th>{t('missingLocations.storeName')}</th>
							<th>{t('indicatorsTable.storeCode')}</th>
							<th>{t('retryLocations.date')}</th>
							<th>{t('retryLocations.days')}</th>
						</tr>
					</thead>
					<tbody className='text-center'>
						{retry_locations.length > 0
							?	retry_locations.map((retry_location, idx) => (
									<tr
										key={idx}
										style={{ height: '53px', verticalAlign: 'middle', cursor: 'pointer' }}
										onClick={() => displayLocationInfo(retry_location)}
									>
										<td>{retry_location?.location?._id?.$oid}</td>
										<td>{retry_location?.location?.name}</td>
										<td>{retry_location?.location?.code}</td>
										<td>{epochToLocalDate(retry_location?.date?.$date)}</td>
										<td>{cycleDaysInfo(retry_location?.date?.$date)[1]}</td>
									</tr>
								))
							:	<tr style={{ height: '53px', verticalAlign: 'middle' }}>
									<td colSpan={18}>{t('retryLocations.error')}</td>
								</tr>
						}
					</tbody>
				</Table>
			</Card>
		</Container>
	);
}

export default IndicatorsRetry;
