const find_indicator_ranking = (rules = [], indicator_id = '') => {
	const indicatorFound = rules.find(r => r?.indicator?.$oid === indicator_id);

	let high_ranking = 0;

	if (indicatorFound) {
		high_ranking = Object.keys(indicatorFound.ranking).find(k => indicatorFound.ranking[k].name === 'Bien Ejecutado') || 0;
	}

	return high_ranking;
}

export default find_indicator_ranking;
